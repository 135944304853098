<!--
 * @Author: snltty
 * @Date: 2021-10-12 09:32:33
 * @LastEditors: snltty
 * @LastEditTime: 2021-10-12 10:58:12
 * @version: v1.0.0
 * @Descripttion: 功能说明
 * @FilePath: \pc\src\views\News.vue
-->
<template>
    <div class="news">
        <ul class="clearfix">
            <template v-for="(item,index) in page.Data" :key="index">
                <li>
                    <router-link :to="{name:'New',query:{id:item.ID}}">
                        <dl>
                            <dt :style="{'background-image':`url(${item.cover})`,'height':`${height}px`}"></dt>
                            <dd class="time">{{item.AddDatetime.split(' ')[0]}}</dd>
                            <dd>
                                <h3>{{item.Title}}</h3>
                                <p>{{item.summary}}</p>
                            </dd>
                        </dl>
                    </router-link>
                </li>
            </template>
        </ul>
        <div class="pages">
            <el-pagination background layout="prev, pager, next" @current-change="onChangePage" :total="page.Count" :page-size="page.PageSize" :current-page="page.PageIndex">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { onMounted, onUnmounted, reactive, toRefs } from 'vue-demi'
export default {
    setup () {
        const state = reactive({
            page: {
                Count: 0,
                Data: [],
                PageIndex: 1,
                PageSize: 10
            },
            height: 500
        });

        const getImg = (content) => {
            let imgReg = /<img.*?(?:>|\/>)/gi //匹配图片中的img标签
            let srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i // 匹配图片中的src
            let arr = content.match(imgReg)  //筛选出所有的img
            if (arr.length > 0) {
                return arr[0].match(srcReg)[1];
            }
            return '';
        }

        const loadData = () => {
            axios.get('https://api.qbcode.cn:8081/api/robotcar/news/page', {
                params: {
                    p: state.page.PageIndex,
                    ps: state.page.PageSize
                }
            }).then((res) => {
                if (res.data.State == 'Success') {
                    res.data.Data.Data.forEach(c => {
                        c.cover = getImg(c.Content);
                        c.summary = c.Content.replace(/<.*?>/g, "").substring(0, 50);
                    });
                    console.log(res.data.Data);
                    state.page = res.data.Data;
                }
            });
        }
        const onChangePage = () => {
            loadData();
        }

        const onresize = () => {
            let w = document.body.offsetWidth;
            if (w > 768) {
                state.height = w * 0.49 * 0.618;
            } else {
                state.height = w * 0.618;
            }
        }
        onMounted(() => {
            loadData();
            window.addEventListener('resize', onresize);
            onresize();
        });
        onUnmounted(() => {
            window.removeEventListener('resize', onresize);
        });

        return {
            ...toRefs(state), onChangePage
        }
    }
}
</script>

<style lang="stylus" scoped>
@media screen and (max-width: 768px)
    .news
        ul
            li
                width: 100% !important;

                &:nth-child(odd)
                    margin-right: 0;

.pages
    text-align: center;
    padding: 3rem 0;

.news
    ul
        width: 95%;
        margin: 3rem auto 1rem auto;

        li
            width: 49%;
            margin-bottom: 3rem;
            position: relative;
            float: left;

            &:nth-child(odd)
                margin-right: 2%;

            dt
                overflow: hidden;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;

            .time
                position: absolute;
                left: 0;
                top: 2rem;
                background: #309d2b;
                color: #fff;
                font-size: 2rem;
                padding: 0.6rem;

            h3
                font-size: 2.8rem;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-top: 0.6rem;
                color: #333;
                font-weight: 400;

            p
                color: #595959;
                font-size: 1.8rem;
                line-height: 4rem;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
</style>